import InfoBlock from "../../../../src/components/custom-blocks/info-block";
import IdeCodeBlock from "../../../../src/components/custom-blocks/ide-code-block";
import Disclaimer from "../../../../src/posts/2021-10-08-ergodox-qmk-animation/disclaimer";
import Eeprom from "../../../../src/posts/2021-10-08-ergodox-qmk-animation/eeprom";
import { ChangeAllAnimation, ChangeSideAnimation, FadeOutAnimation, EaseComparisonAnimation, EaseAnimation } from "../../../../src/posts/2021-10-08-ergodox-qmk-animation/led-animation";
import * as React from 'react';
export default {
  InfoBlock,
  IdeCodeBlock,
  Disclaimer,
  Eeprom,
  ChangeAllAnimation,
  ChangeSideAnimation,
  FadeOutAnimation,
  EaseComparisonAnimation,
  EaseAnimation,
  React
};