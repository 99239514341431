import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "ℹ️-when-does-it-split-clusters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#%E2%84%B9%EF%B8%8F-when-does-it-split-clusters",
        "aria-label": "ℹ️ when does it split clusters permalink",
        "className": "autolink-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ℹ️ When does it split clusters?`}</h4>
    <p>{`First off: I'm not a data scientist, and I just fiddled around until I had a UI that felt good to me.`}</p>
    <p>{`I used hierarchical clustering from a data clustering library. To determine the number of clusters (k), I arbitrarily ran the clustering algorithm for k=2 through k=8. After that, I picked the clustering that had the lowest standard deviation in silhouette coefficients. (Intuitively, this optimized for clusters that had a visually-similar density of cards).`}</p>
    <p>{`(I also thought hierarchical clustering could help make the "splitting" and "joining" behaviors more feel more intuitive, since k=k-1 would cause two clusters to join together, and so on. The hierarchy wasn't always consistent after card movements, however.)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      