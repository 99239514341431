import InfoBlock from "../../../../src/components/custom-blocks/info-block";
import DeterminingClusters from "../../../../src/posts/2023-04-11-assignment-problem/determining-clusters";
import Greedy from "../../../../src/posts/2023-04-11-assignment-problem/greedy";
import { Table1, Table2, Table3, WikipediaTable } from "../../../../src/posts/2023-04-11-assignment-problem/tables";
import * as React from 'react';
export default {
  InfoBlock,
  DeterminingClusters,
  Greedy,
  Table1,
  Table2,
  Table3,
  WikipediaTable,
  React
};