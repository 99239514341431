import CommitMessageEditor from "../../../../src/posts/2019-07-02-code-review-experience/commit-message-editor";
import CommitMessageTemplate from "../../../../src/posts/2019-07-02-code-review-experience/commit-message-template";
import DecrementTs1 from "../../../../src/posts/2019-07-02-code-review-experience/decrement-ts-1";
import DecrementTs2 from "../../../../src/posts/2019-07-02-code-review-experience/decrement-ts-2";
import EditRebase from "../../../../src/posts/2019-07-02-code-review-experience/edit-rebase";
import GitAddPatch from "../../../../src/posts/2019-07-02-code-review-experience/git-add-patch";
import GoodCommitHistory from "../../../../src/posts/2019-07-02-code-review-experience/good-commit-history";
import InfoBlock from "../../../../src/components/custom-blocks/info-block";
import ResetAndPatch from "../../../../src/posts/2019-07-02-code-review-experience/reset-and-patch";
import ResetAndPatchDiff from "../../../../src/posts/2019-07-02-code-review-experience/reset-and-patch-diff";
import RewordRebase from "../../../../src/posts/2019-07-02-code-review-experience/reword-rebase";
import * as React from 'react';
export default {
  CommitMessageEditor,
  CommitMessageTemplate,
  DecrementTs1,
  DecrementTs2,
  EditRebase,
  GitAddPatch,
  GoodCommitHistory,
  InfoBlock,
  ResetAndPatch,
  ResetAndPatchDiff,
  RewordRebase,
  React
};