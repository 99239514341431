import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "what-does-a-good-commit-history-look-like",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#what-does-a-good-commit-history-look-like",
        "aria-label": "what does a good commit history look like permalink",
        "className": "autolink-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What does a good commit history look like?`}</h4>
    <p>{`I find that my individual commits often don’t map to individual files—they instead involve small related chunks across multiple files.`}</p>
    <p><em parentName="p">{`For example…`}</em></p>
    <ul>
      <li parentName="ul">{`✅ `}<strong parentName="li">{`Good commit 1:`}</strong>{` Add a new function `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`incrementByAmount`}</code>{` to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`utils.js`}</code>{` and a test to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`utils.tests.js`}</code>{`.`}</li>
      <li parentName="ul">{`✅ `}<strong parentName="li">{`Good commit 2:`}</strong>{` Add another new function `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`decrementByAmount`}</code>{` to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`utils.js`}</code>{` and a test to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`utils.tests.js`}</code>{`.`}</li>
    </ul>
    <p><em parentName="p">{`Compare that with…`}</em></p>
    <ul>
      <li parentName="ul">{`🚫 `}<strong parentName="li">{`Bad commit 1:`}</strong>{` Add two functions to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`utils.js`}</code>{`.`}</li>
      <li parentName="ul">{`🚫 `}<strong parentName="li">{`Bad commit 2:`}</strong>{` Add tests to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`utils.tests.js`}</code>{`.`}</li>
    </ul>
    <p>{`When viewing one of the “bad commits” individually, the reviewer may be thinking “are they going to test this function?” or “what function is this test testing?”`}</p>
    <p>{`(For a small change like this, unstructured commits aren’t a big deal. Larger PRs, however, may contain a lot of unrelated changes within one file. While that may also be a sign that the file needs re-architecting, that’s out of scope for this post.)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      